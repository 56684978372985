import '../sass/style.scss';

import { $, $$ } from './modules/bling';

import alertify from 'alertify.js';


window.showDialog = function(title, successAction) {
    window.showDialog(title, successAction, { ok: "Ok", cancel: "Avbryt" } );
};

window.showDialog = function(title, successAction, options) {
    alertify.okBtn(options.ok)
            .cancelBtn(options.cancel)
            .confirm( title, function(e) {
                if (e) {
                    location.href = successAction;
                } else {

                }
            } );
}

window.dropDownVisibilityToggler = (selectorToHide, event) => {
    document.querySelectorAll(selectorToHide).forEach((el) => el.classList.add('row--hide'));
    const toShow = document.getElementById(event.target.value);
    toShow && toShow.classList.toggle('row--hide');
}
